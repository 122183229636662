import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ClockConfig, HeaderWorldClock, HomePageCompanyLogo, HomePageRandomJoke, HomePageStarredEntities, TemplateBackstageLogo } from '@backstage/plugin-home';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Content, Header, Page } from '@backstage/core-components';
import { makeStyles } from '@material-ui/core';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { HomePagePagerDutyCard } from '@pagerduty/backstage-plugin';

const useStyles = makeStyles(theme => ({
    searchBarInput: {
        maxWidth: '60vw',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '50px',
        boxShadow: theme.shadows[1],
    },
    searchBarOutline: {
        borderStyle: 'none'
    }
}));

const useLogoStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(5, 0),
    },
    svg: {
        width: 'auto',
        height: 100,
    },
    path: {
        fill: '#7df3e1',
    },
}));


export const HomePage = () => {
    const classes = useStyles();
    const { svg, path, container } = useLogoStyles();
    const clockConfigs: ClockConfig[] = [
        {
            label: 'LON',
            timeZone: 'Europe/London',
        },
        {
            label: 'NYC',
            timeZone: 'America/New_York',
        },
        {
            label: 'UTC',
            timeZone: 'UTC',
        },
    ];
    const timeFormat: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    };
    return (
        <SearchContextProvider>
            <Page themeId='home'>
                <Content>
                    <Grid container justifyContent="center" spacing={6}>
                        <Header title="Welcome to Backstage" pageTitleOverride="Home" subtitle="The Home of Curve Engineering Team">
                            <HeaderWorldClock
                                clockConfigs={clockConfigs}
                                customTimeFormat={timeFormat}
                            />
                        </Header>
                        <HomePageCompanyLogo
                            className={container}
                            logo={<TemplateBackstageLogo classes={{ svg, path }} />}
                        />
                        <Grid container item xs={12} justifyContent='center'>
                            <HomePageSearchBar
                                classes={{ root: classes.searchBarInput }}
                                InputProps={{ classes: { notchedOutline: classes.searchBarOutline } }}
                                placeholder="Search"
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12} md={6}>
                                <HomePageStarredEntities />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <HomePageRandomJoke />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12} md={6}>
                                <HomePagePagerDutyCard
                                    title='Paytech'
                                    name='Paytech'
                                    serviceId='PRVQCW1'
                                    readOnly={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <HomePagePagerDutyCard
                                    title='Product'
                                    name='Product'
                                    serviceId='PQZZUUY'
                                    readOnly={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Content>
            </Page>
        </SearchContextProvider>
    );
}
