import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
  scmAuthApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  useApi,
  configApiRef,
  createApiFactory,
  gitlabAuthApiRef,
} from '@backstage/core-plugin-api';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      gitlab: gitlabAuthApiRef,
    },
    factory: ({ gitlab }) =>
      ScmAuth.merge(
        ...useApi(configApiRef).getConfigArray('integrations.gitlab').map(c => 
          ScmAuth.forGitlab(gitlab, {
            host: c.getString('host'),
          })
        ),
      ),
  }),
];
